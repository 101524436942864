import React from "react"
import { Link } from "gatsby"
import { Container } from "./Container"
import { Images } from "./Images"

export const PoolsContent = () => {
  return (
    <section className="">
      <Container>
        <div className="services-content-wrapper">
          <p>
            Be it for residential or commercial purpose, we offer a wide range
            of services for swimming pools. From designing and building
            custom-made pools to ready-made pools, concrete or fiberglass,
            indoor or outdoor, we are proud to be a one-stop service for pools
            and fountains.
          </p>
          <p>
            To know more,{" "}
            <Link to="/contact-us" className="text-secondary underline">
              contact us.
            </Link>
          </p>
          <p>
            For pool equipments and filters, visit our sales division,{" "}
            <a
              href="http://www.environmentalsystems.in/"
              className="text-secondary underline"
            >
              Environmental Systems
            </a>
          </p>
        </div>
        <section className="mb-10">
          <div className="font-sans font-bold text-primary text-xl mb-4 text-center">
            Gallery
          </div>

          <Images
            className="flex flex-wrap p-4 justify-center"
            filterByText="pools"
            imageClassName="w-32 m-1 cursor-pointer hover:shadow-lg"
            enableGallery={true}
          />
        </section>
      </Container>
    </section>
  )
}
